    const popupComponent = document.querySelector('.popup-component');
    const popupComponentBoday = document.querySelector('.popup-content-body');
    const watchVideoBtn = document.querySelector('.vedio_popup_active  a');
    const videoElement = popupComponent.querySelector('video');
    
    function showPopup() {
        popupComponent.classList.add('active');
    }

    function hidePopup(e) {
        if (!popupComponent.contains(e.target) || e.target.tagName !== 'VIDEO') {
            popupComponent.classList.remove('active');
        }
    }

    watchVideoBtn.addEventListener('click', function(e) {
        e.preventDefault();  // Prevent default link behavior
        e.stopPropagation(); // Stop click from propagating to window
        showPopup();
    });

    // Stop propagation when clicking inside the popup (so it won't close when clicking the video)
    popupComponentBoday.addEventListener('click', function(e) {
        e.stopPropagation(); // Prevent click inside the popup from closing it
    });

    // Add event listener to window to hide the popup when clicking outside the video
    window.addEventListener('click', function(e) {
        hidePopup(e);
    });

    // Close popup when clicking on close button
    const closeBtns = document.querySelectorAll('.jsClose');
    closeBtns.forEach(function(closeBtn) {
        closeBtn.addEventListener('click', function(e) {
            e.stopPropagation(); // Prevent close button click from propagating to window
            popupComponent.classList.remove('active');
        });
    });